import { NavLink } from "react-router-dom";
export default function ExternalLinks() {
  return (
    <div className="flex flex-row justify-center gap-4 text-xs md:text-sm mt-[-10px]">
      <div className="flex gap-6 justify-center">
        <NavLink
          key="refund policy"
          target="_blank"
          to="https://b3sciences.com/refunds-returns/"
        >
          <div className="flex flex-col items-center text-center">
            <img
              alt="refund-policy"
              src="./assets/images/refund-policy.png"
              className="w-[60px] h-[60px]"
            />
            <p className="mt-[-8px] text-xs bold">Refund Policy</p>
          </div>
        </NavLink>
        <NavLink
          key="warranty"
          target="_blank"
          to="https://b3sciences.com/warranty/"
        >
          <div className="flex flex-col items-center text-center">
            <img
              alt="refund-policy"
              src="./assets/images/band-warranty.png"
              className="w-[60px] h-[60px]"
            />
            <p className="mt-[-8px] text-xs bold">Bands Warranty</p>
          </div>
        </NavLink>
      </div>
      <div className="flex gap-4 justify-center">
        <NavLink
          key="customer support"
          target="_blank"
          to="https://b3sciences.com/contact/"
        >
          <div className="flex flex-col items-center text-center">
            <img
              alt="refund-policy"
              src="./assets/images/customer-support.png"
              className="w-[60px] h-[60px]"
            />
            <p className="mt-[-8px] text-xs bold">Customer Support</p>
          </div>
        </NavLink>
        <NavLink
          key="privacy"
          target="_blank"
          to="https://b3sciences.com/privacy-policy/"
        >
          <div className="flex flex-col items-center text-center">
            <img
              alt="refund-policy"
              src="./assets/images/privacy-policy.png"
              className="w-[60px] h-[60px]"
            />
            <p className="mt-[-8px] text-xs bold">Privacy Policy</p>
          </div>
        </NavLink>
      </div>
    </div>
  );
}