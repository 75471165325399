import { useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import Carousel from "../../components/Carousel";
import { getCart } from "../../utils/localstorage";
import AddToCart from "../nutrition-products/AddToCart";
import CertificationCourseDetails from "./CertificationCourseDetails";

const CourseImages = ['certification-course1.png', 'certification-course2.png', 'certification-course3.png', 'certification-course4.png']

export default function CertificationCourse() {
  const [addCourse, setAddCourse] = useState(false);
  const cart = getCart();
  const isCourseInCart = cart.filter(p => p.product.Sku === 'BBCC').length > 0

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <div>
        <img alt="money-guarantee" src="./assets/images/guarantee.jpg" className="absolute md:w-[100px] w-[60px] top-6 right-5 md:top-2 md:right-2" />
      </div>
      <h1 className='md:text-6xl pt-2 text-5xl'>
        <span>B</span>
        <span className='text-[#00adef] mr-3'>3</span>
        <span>BFR CERTIFICATION COURSE</span>
      </h1>
      <div className="flex flex-col gap-16">
        <div className="flex flex-col">
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-4">
            <div className="flex flex-col lg:mt-7 mt-5 relative">
              <CertificationCourseDetails />
              <img alt="certification logo" src="./assets/images/BFR-Certified.png" className="right-0 sm:top-[-30px] w-[130px] sm:absolute relative mx-auto"/>
              <div onClick={() => setAddCourse(true)}>
                <AddToCart sku='BBCC' />
              </div>
              { (addCourse || isCourseInCart) && <>
                <p className="pt-6 text-lg font-light">ADDITIONAL FAMILY <br /> MEMBERS OR COLLEAGUES</p>
                <AddToCart sku='BBCCadd' />
              </>}
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={CourseImages} />
            </div>
          </div>
          
        </div>
      </div>
      <div className='w-full flex justify-between mt-10 sm:flex-row flex-col'>
        <NavLink key='member options' to="/become-a-member">
          <Button className="lg:text-2xl hover:bg-[#008bb9] text-lg px-6 py-4 rounded-full font-light sm:w-auto w-full sm:mt-0 mt-2">
            {' MEMBER OPTIONS'}
          </Button>
        </NavLink>
        <NavLink key='check out' to="/check-out">
          <Button className="lg:text-2xl hover:bg-[#008bb9] text-lg px-6 py-4 rounded-full font-light sm:w-auto w-full sm:mt-0 mt-2">
            {'CHECK OUT >'}
          </Button>
        </NavLink>
      </div>
    </section>
  )
}