import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import Carousel from "../../components/Carousel";
import AddToCart from "./AddToCart";
import B3Alert from "./B3Alert";
import B3Meal from "./B3Meal";
import B3Start from "./B3Start";
import B3StartCaffeineFree from "./B3StartCaffeineFree";
import Subscribe from "./Subscribe";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";

const B3StartImages = ['22.png', '25.png']
const B3StartCaffeineFreeImages = ['caffeine-free-1.png', 'caffeine-free-2.png']
const B3MealImages = ['14.png', '18.png']
const B3AlertImages = ['27.png', '29.png']

export default function NutritionProducts() {

  const b3StartSubscribePrice = 69.99
  const b3MealSubscribe = 59.99
  const b3AlertSubscribe = 54.99

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <h1 className='md:text-6xl pt-2 text-4xl sm:text-5xl'>
        <span>B</span>
        <span className='text-[#00adef] mr-3'>3</span>
        <span>FIT NUTRITION PRODUCTS</span>
      </h1>
      <div className="flex flex-col gap-16">
        <div className="flex flex-col">
          <h1 className='md:text-3xl pt-2 text-2xl mt-3 font-bold'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>START</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-2">
            <div className="flex flex-col">
              <B3Start />
              <AddToCart sku='RCStart' />
              <Subscribe price={b3StartSubscribePrice} sku='Start1' />
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={B3StartImages} allowExpand/>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h1 className='md:text-3xl pt-2 text-2xl mt-3 font-bold'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>START CAFFEINE FREE</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-2">
            <div className="flex flex-col">
              <B3StartCaffeineFree />
              <AddToCart sku='RCStartFR' />
              <Subscribe price={b3StartSubscribePrice} sku='StartFR1' />
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={B3StartCaffeineFreeImages} allowExpand/>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h1 className='md:text-3xl pt-2 text-2xl mt-3 font-bold'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>MEAL</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-x-6">
            <div className="flex flex-col">
              <B3Meal />
              <AddToCart sku='RCMeal' />
              <Subscribe price={b3MealSubscribe} sku='Meal1' />
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={B3MealImages} allowExpand/>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h1 className='md:text-3xl pt-2 text-2xl mt-3 font-bold'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>ALERT</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-x-6">
            <div className="flex flex-col">
              <B3Alert />
              <AddToCart sku='RCAlert' />
              <Subscribe price={b3AlertSubscribe} sku='Alert1' />
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={B3AlertImages} allowExpand/>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
        <NavLink key="b3 bands" to="/bands">
          <Button className="uppercase hover:bg-[#008bb9] text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            <div className="inline-block pr-1 relative top-[3px]">
              <ArrowLeftIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
            {"Back"}
          </Button>
        </NavLink>
        <NavLink key="nutrition products" to="/become-a-member">
          <Button className="uppercase hover:bg-[#008bb9] text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            Next
            <div className="inline-block pl-1 relative top-[3px]">
              <ArrowRightIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
          </Button>
        </NavLink>
      </div>

    </section>
  )
}